@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.button {
  background: none;
  border: 0;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  padding: 0;
  text-decoration: underline;
  height: 40px;

  @include media-breakpoint-up('sm') {
    margin: 0;
  }

  .icon {
    display: flex;
    margin-right: 12px;

    .active {
      path {
        fill: $colour-success-green;
      }
    }
  }

  &:focus {
    outline-color: $colour-focus-blue;
    outline-style: solid;
    outline-width: 4px;
  }
}

.noLabel {
  width: 40px;
  outline: none;

  .icon {
    display: block;
    margin: 0;
    width: 20px;

    svg {
      height: 100%;
      width: 100%;

      path {
        fill: $colour-waitrose-grey;
      }
    }

    .active {
      path {
        fill: $colour-success-green;
      }
    }
  }
}
