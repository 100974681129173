@import '@johnlewispartnership/wtr-ingredients/foundations/colours';

.wrapper {
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  color: $colour-offer-red;
  text-decoration: underline;

  &:hover, &:focus {
    color: $colour-offer-red;
  }  
}

.link {
  margin-left: 8px;
}
