@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

$spacing-unit: 4px;

.productTags {
  font-size: $font-size-small;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-right: $spacing-unit;
  }
}

.withDetails {
  margin-bottom: $spacing-unit;
}

.tagsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.hasLongText {
  display: flex;
  align-items: center;

  @include media-breakpoint-up('sm') {
    display: block;
  }
}