@import '../../../../../styles/settings/variables';

.icon {
  position: relative;
  display: inline-block;
  width: 1.125em;
  height: 1.125em;
  text-align: center;
  vertical-align: text-bottom;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.backgroundImageIcon {
  composes: icon;

  &:before {
    background-size: contain;
  }
}

.characterIcon {
  composes: icon;

  &:before {
    border-radius: 50%;
    color: $colour-white;
  }
}

.glyphIcon {
  composes: icon;
  vertical-align: text-top;
  line-height: 1;

  &:before {
    font-family: 'WaitroseGlyph';
  }
}

.analgesic {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_analgesic.gif');
  }
}

.fairtrade {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_fairtrade.gif');
  }
}

.freezing {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_freezing.gif');
  }
}

.frozen {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_frozen.gif');
  }
}

.leadTime {
  composes: glyphIcon;

  &:before {
    content: '\e92b';
  }
}

.microwaveable {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_microwave.gif');
  }
}

.organic {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_organic.gif');
  }
}

.previouslyFrozen {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_previouslyfrozen.gif');
  }
}

.readyToServe {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/background-svg/readyToServe.svg');
  }
}

.requiresCooking {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/background-svg/requiresCooking.svg');
  }
}

.requiresHeating {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/background-svg/requiresHeating.svg');
  }
}

.serveHotOrCold {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/background-svg/serveHotOrCold.svg');
  }
}

.servings {
  composes: glyphIcon;

  &:before {
    content: '\e92d';
  }
}

.serviceCounter {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_servicecounter.gif');
  }
}

.vegetarian {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_veg.gif');
  }
}

.waitroseOwnLabel {
  composes: backgroundImageIcon;

  &:before {
    background-image: url('./img/icon_waitroseown.gif');
  }
}
