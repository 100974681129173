@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';

.wrapper {
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  background: $colour-white;

  @include media-breakpoint-up('sm') {
    visibility: hidden;
    transition: visibility 0s ease-in-out 0.25s;

    &.active {
      visibility: visible;
      transition: visibility 0s ease-in-out 0s;
    }
  }
}

.imageWrapper {
  gap: 16px;

  .image {
    width: 125px;
    height: 72px;
    flex-shrink: 0;
  }

  @include media-breakpoint-up('sm') {
    width: 100%;
    height: 132px;
    position: relative;
    overflow: hidden;

    .image {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: calc(100% + 2px);
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    .linkWrapper {  
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 8px 16px;
      width: 100%;
      z-index: 2;
      // stylelint-disable-next-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals
      background: rgba($colour-waitrose-grey, 0.95);
      color: $colour-white;
    }
  }
}

.logoWrapper {
  gap: 8px;

  .image {
    width: 40px;
    height: auto;
    flex-shrink: 0;
  }
}

.linkWrapper {
  text-align: left;
  text-decoration: underline;
}

.icon {
  display: inline-flex;
  margin-right: 8px;

  > svg {
    height: 20px;
    width: 20px;
  }
}

.link {
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;

  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
}
