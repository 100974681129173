// sass-lint:disable no-vendor-prefixes
// sass-lint:disable no-css-comments

@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../wdx/mixins/typography';
@import '../variables';

$product-title-font-size: $font-size-base;
$product-title-line-height: 1.5;
$product-title-no-of-lines: 3;
$default-product-title-height: $product-title-font-size * $product-title-line-height * $product-title-no-of-lines;

.podHeader {
  @extend %pod-section;
  display: flex;
  flex: {
    direction: column;
    grow: 1;
  }
  float: left;

  &.fullSize {
    float: none;
    width: auto;
    margin: 0;

    padding: {
      left: $product-pod-horizontal-padding;
      right: $product-pod-horizontal-padding;
    }
  }

  @include media-breakpoint-up('sm') {
    flex: 1 0 120px;
    float: none;
    max-height: 136px;
    width: auto;
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}

.nameLink {
  min-height: 96px;
  display: block;
}

.nameWrapper {
  @include paragraph('medium');
  text-align: left;
  margin: 0;
  padding-right: 16px;

  &.fullSize {
    height: $font-waitrose-title-size-small * $product-title-line-height * $product-title-no-of-lines;
  }

  @include media-breakpoint-up('sm') {
    height: initial;
    padding-right: 0;
    overflow: hidden;
  }
}

.ellipses {
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  /* stylelint-enable */
}

.name {
  @include paragraph('medium');

  &.fullSize {
    font-size: $font-waitrose-title-size-small;
  }

  &:hover {
    text-decoration: underline;
  }
}

.productPodAttr {
  margin: 20px 0;
}

.sizeMessage {
  color: $colour-primary-grey;
  font-size: $font-waitrose-body-size-small;
  line-height: 1.5;

  &.fullSize {
    display: none;

    @include media-breakpoint-up('sm') {
      display: block;
    }
  }

  @include media-breakpoint-up('sm') {
    min-height: 18px;
  }
}

.starRating {
  @include star-rating;
}
