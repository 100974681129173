@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

@keyframes hideAnimation {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}


.productPodCounter {
  background-color: $colour-success-green-tint;
  color: $colour-primary-grey;
  height: 38px;
  margin-right: 1px;
  padding: 7px 14px;
  position: relative;
  text-align: left;
  z-index: 2;

  @include media-breakpoint-up('sm') {
    margin: 0;
    border-top: 1px solid $colour-success-green;
    border-left: 1px solid $colour-success-green;
    border-right: 1px solid $colour-success-green;
  }

  &.withBoxShadow {
    box-shadow: 0 1px 8px 0 $colour-primary-grey--alpha-40;
  }
}

.initialMessage {
  background-color: $colour-success-green-tint;
  height: 28px;
  width: 100px;
  position: absolute;
  visibility: hidden;
  z-index: 1;

  &.added {
    animation-delay: 6s;
    animation-duration: .25s;
    animation-fill-mode: forwards;
    animation-name: hideAnimation;
    visibility: visible;
  }

}

.mainMessage {
  position: absolute;

  @include media-breakpoint-up('sm') {
    .tabletWithMealDeals {
      line-height: 24px;
    }
  }
}

.inMealDeal {
  display: flex;
  justify-content: flex-end;

  svg {
    color: $colour-success-green;
    margin: 4px 8px;

    @include media-breakpoint-up('sm') {
      margin: 4px;
    }
  }

  @include media-breakpoint-up('sm') {
    padding-right: 0;

    p {
      line-height: 24px;
    }
  }

}

.text {
  line-height: 50px;
}