.button {
  display: block;
  margin-bottom: 8px;
  padding: 8px 0 16px;
  text-align: left;
  width: 100%;
}

.brandTextWrap {
  white-space: nowrap;
}

.openModalIcon {
  height: 16px;
  margin-left: 6px;
  width: 16px;
  vertical-align: sub;
}