@import '../../../styles/settings/variables';

.deleteButton {
  appearance: none;
  cursor: pointer;
  padding: 0;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    opacity: .6;
    transition: opacity .2s linear;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}
