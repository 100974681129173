@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

$product-pod-horizontal-padding: $grid-vertical-gutter * 1.25;

%pod-section {
  margin-right: 16px;
  @include media-breakpoint-up('sm') {
    margin-right: 0;
    padding: {
      left: $product-pod-horizontal-padding;
      right: $product-pod-horizontal-padding;
    }
  }

  .fullSize & {
    padding: 0;
  }
}

@mixin star-rating() {
  line-height: 1;
  margin-bottom: 0;
}

$small-add-to-trolley: 40px;
$small-add-to-trolley-line-height: $small-add-to-trolley - 2px;
$large-add-to-trolley: 40px;
$large-add-to-trolley-line-height: $large-add-to-trolley - 2px;

%trolley-button {
  font-family: WaitroseGlyph;
  height: $large-add-to-trolley;
  line-height: $large-add-to-trolley-line-height;
  padding: 0;
  width: $large-add-to-trolley;

  @include media-breakpoint-up('sm') {
    height: $small-add-to-trolley;
    line-height: $small-add-to-trolley-line-height;
    width: $small-add-to-trolley;
  }

  &:disabled {
    border: 0;
    opacity: 100;
  }
}

// new pod conflicts
$conflict-overlay-background:  $colour-white;
$conflict-overlay-opacity:  .33;
