@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../variables';

.image {
  @extend %pod-section;
  border: 0;
  float: right;
  z-index:0;

  &.fullSize {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-self: center;
    float: none;
    justify-content: center;
    margin: 10px 0;
    width: fit-content;
  }

  & a {
    display: block;
    margin: 0 auto;
    min-width: initial;
    max-width: initial;
    padding-bottom: 0;
    position: relative;
    width: $pod-image-xxs-width;
  
    @media screen and (min-width: $pod-image-xs-breakpoint) {
      width: $pod-image-xs-width;
    }
  
    @include media-breakpoint-up('sm') {
      // here we cannot set fixed width as in design because in this breakpoint pods are % width size
      // and fixed image width would break the design when the pod width is less then 150px
      // so to keep the image width always at 150px we would need grid's minmax(150px+paddings, 1fr) on the whole pod
      max-width: $pod-image-regular-width;
      width: auto;
    }

    &:focus {
      outline-offset: 0;
    }
  }

  @include media-breakpoint-up('sm') {
    float: none;
    margin: 10px 0;
    position: relative;
    width: 100%;
  }
}

.disabled {
  .placeholder {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $conflict-overlay-background;
      opacity: $conflict-overlay-opacity;
      z-index: $zindex-pod-overlay;
      pointer-events: none;
    }
  }
}

.lazyload {
  height: 0;
  padding-bottom: 100%; // [BM] maintains 1:1 aspect ratio based on parent

  .fullSize & {
    @include media-breakpoint-up('sm') {
      display: flex;
    }
  }

  .prodDetails {
    a {
      display: block;
      color: inherit;

      &:hover {
        color: $colour-waitrose-green;
        text-decoration: none;
      }
    }
  }
}
