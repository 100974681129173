@import '../../settings/variables';

// Base link style in ecom
%link {
  &,
  &:focus,
  &:hover,
  &:active {
    color: $text-color;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
}

%link-light {
  @extend %link;

  &,
  &:focus,
  &:hover,
  &:active {
    font-weight: 400;
  }
}
