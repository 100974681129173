@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/objects/links/links';
@import '../../styles/tools/mixins';

.starRating {
  color: $colour-earl-grey;
  cursor: pointer;
  display: inline-flex;
  line-height: 1;

  &.lighter {
    color: $colour-primary-grey;
    font-size: $font-waitrose-body-size-small;

    .ratingText {
      font-weight: lighter;
    }
  }
}

.ratingText {
  @extend %link-light;
  font-family: $font-family-base;
  padding: 0 5px;
  vertical-align: middle;
}

.isSizeSmall {
  .ratingText {
    padding: 0;
    font-family: inherit;
    text-decoration: none;
    vertical-align: text-top;
  }

  &:hover,
  &:focus,
  &:active {
    .ratingText {
      color: inherit;
    }
  }
}
