@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../variables';

.trolleyControls {
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100%;

  .addCTA {
    width: 100%;
  }

  .btnCTA {
    button {
      height: 40px;
      width: 40px;
      padding: 0;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &:nth-child(-n + 3) {
      margin-right: 8px;
    }

    &.hide {
      display: none;
    }
  }

  .form {
    display: flex;
    grid-column: 2;
    grid-row: 1 / 3;
    align-items: flex-end;
    z-index: 1;
  
    &.withUomSelector {
      grid-row: auto;
    }

    @include media-breakpoint-up('sm') {
      grid-column: initial;
      margin-top: 12px;
      grid-row: initial;
    }
  }

  .uom {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-bottom: .625rem;
    grid-column: 2;

    @include media-breakpoint-up('sm') {
      grid-column: initial;
      margin-top: ($grid-vertical-gutter - 1px);
    }

    > *:first-child {
      margin-right: 8px;
    }

    label {
      padding-left: 1.8rem !important;
    }
  }

  .inputWrapper {
    display: flex;
    border: 1px solid $colour-primary-grey;
    font-size: $font-size-base;
    font-weight: 400;
    height: $large-add-to-trolley;
    min-width: $large-add-to-trolley;
    max-width: 100px;
    margin: 0 8px 0 0;
    padding: 0 10px;
    flex-grow: 1;
    cursor: text;

    @include media-breakpoint-up('sm') {
      font-size: $font-body-size-small;
    }

    &.hide {
      display: none;
    }

    &:focus-within {
      box-shadow: none;
      outline-color: $colour-focus-blue;
      outline-offset: 4px;
      outline-style: solid;
      outline-width: 4px;
    }

    .inputUom {
      display: none;
    }

    &.showUom {
      & .inputUom {
        display: flex;
        align-items: center;
        height: 100%;
        text-align: left;
        color: $colour-earl-grey;
      }
    }

    .input {
      appearance: textfield;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      font-size: inherit;
      height: 100%;
      margin: 0;
      text-align: left;
      width: 100%;
      outline: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }

  .updateBtn {
    max-width: 88px;
    min-width: 88px;

    &.hide {
      display: none;
    }
  }

  .updated {
    border: 1px solid $colour-success-green;
    background: $colour-success-green-tint;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  @include media-breakpoint-up('sm') {
    grid-template-columns: none;
  }
}

.prices {
  display: flex;
  align-content: initial;
  justify-content: flex-start;
  flex: {
    basis: auto;
    direction: column;
    grow: 1;
    shrink: 0;
  }
  font: {
    size: $font-waitrose-title-size-small;
    weight: 400;
  }
  line-height: 1;
  min-height: 40px;
  min-width: initial;
  max-width: initial;
  width: $pod-image-xxs-width;
  text-align: left;
  margin-right: 1rem;
  grid-column: 1;

  @media screen and (min-width: $pod-image-xs-breakpoint) {
    width: $pod-image-xs-width;
  }

  @include media-breakpoint-up('sm') {
    grid-column: initial;
    grid-row: initial;
    margin-right: 0;
    margin-bottom: 1px;
    flex: {
      direction: row;
      wrap: wrap;
    }
    max-width: initial;
    align-items: flex-end;
    justify-content: space-between;
    min-height: initial;
    flex-grow: 0;
    width: auto;
  }
}

.priceInfo {
  font: {
    size: $font-waitrose-body-size-small;
    weight: 200;
  }
  line-height: 1.5;
  margin: 0;
  white-space: nowrap;
}

.priceEst {
  composes: priceInfo;
  font-weight: 400;

  abbr {
    border: 0;
    text-decoration: none;
  }
}

.itemPrice {
  margin-top: 0;
  margin-right: 8px;
  display: inline;
  align-items: flex-end;
  line-height: 1.4;
}

.pricePerUnit {
  composes: priceInfo;
  display: inline;
  flex-grow: 0;
  align-items: flex-end;
  margin-top: 0;

  @include media-breakpoint-up('sm') {
    display: inline-flex;

    .fullSize & {
      margin-left: 0;
    }
  }
}

.chipWrapper {
  grid-column: 1;

  @include media-breakpoint-up('sm') {
    margin-bottom: 4px;
  }
}

.conflictWrapper {
  display: flex;

  $h-padding: $grid-vertical-gutter;
  $min-height: 2 * 24px + 8px + 11px; // min 2 lines + paddings
  border: 0;
  grid-column: 2;
  grid-row: 1 / 3;
  align-items: flex-end;
  overflow-x: hidden;

  @include media-breakpoint-up('sm') {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: initial;
    grid-row: initial;
    padding: 8px $h-padding 11px; // bottom padding + negative bottom margin for button:focus outline
    margin: 0 -1 * $h-padding -1 * $h-padding; // negative horiz margins for 100% width top border
    border-top: 1px solid $colour-oyster-grey;

    & > div {
      max-width: 154px;
    }
  }
}

.conflictWrapperAlternativesButton {
  @include media-breakpoint-up('sm') {
    padding: 8px $grid-vertical-gutter 16px;

    & > div {
      max-width: initial;
    }

    button {
      margin-top: 8px;
    }
  }
}

.viewProductButton {
  min-width: 0;
  @include media-breakpoint-up('sm') {
    margin-top: 12px;
  }
}

