
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../variables';

$entertaining-icon-size: 24px;
$entertaining-left-padding-xs: $entertaining-icon-size + 11px;

.podEntertainingAttributes {
  display: table;
  position: relative;
  width: calc(100% - #{$grid-vertical-gutter});
  min-height: 3em;
  padding-top: $entertaining-icon-size * .25;
  margin-top: $grid-vertical-gutter * .625;
  font-weight: 200;
  line-height: 24px;

  @include media-breakpoint-up('sm') {
    display: block;
    position: relative;
    width: auto;
    padding-top: 10px;
    margin-top: 0;
  }
}

.entertainingDetails {
  display: table-cell;
  vertical-align: middle;
  text-align: left;

  @include media-breakpoint-up('sm') {
    display: block;
  }
}

.ratingWrapper {

  @include media-breakpoint-down('xs') {
    margin-bottom: -8px;
  }
}
