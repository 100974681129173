@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

%colour-oyster {
  color: $colour-primary-grey;
  background-color: $colour-oyster-grey;
}

.badge {
  position: relative;
  top: 2px;
  font-size: $font-size-small;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;

  &.hideInMobile {
    @include media-breakpoint-down('xs') {
      display: none;
    }
  }

  &.variant-entertaining {
    @extend %colour-oyster;
    font-size: $font-size-very-small;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: $line-height-base;
    padding: 0 4px;
  }

  // colour scheme overwriting utility classes
  &.oyster {
    @extend %colour-oyster;
  }
}