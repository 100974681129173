@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.optionsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  position: absolute;
  right: 2px;
  top: 2px;
  height: 36px;
  width: 36px;
  padding: 0;
  z-index: 3;
}

.icon {
  transition: transform 0.25s ease-in-out;
  height: 15px;
  width: 15px;

  &.openIcon {
    transform: rotateX(180deg);
  }
}

.optionsWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
  margin-right: 1px;
  margin-bottom: 2px;

  @include media-breakpoint-up('sm') {
    background: $colour-white;
    border-left: 1px solid $colour-success-green;
    border-right: 1px solid $colour-success-green;
    position: absolute;
    left: 0;
    right: 0;
    transform: translate(0, -100%);
    transition: transform 0.25s ease-in-out;
    margin: 0;

    &.withoutPadding {
      padding: 0;
    }

    &.openOptions {
      transform: translate(0, 0);
      box-shadow: 0 1px 8px 0 $colour-primary-grey--alpha-40;
    }
  }
}
