@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.listsModal {
  img {
    width: 112px;
  }
}

.podImage {
  img {
    border: 0;
  }
}

.productPod {
  composes: podImage;

  img {
    max-width: 100%;
    width: 140px;

    @include media-breakpoint-up('sm') {
      max-width: 170px;
      width: 100%;
    }
  }
}

.carousel {
  composes: podImage;

  img {
    width: 100%;

    @include media-breakpoint-up('sm') {
      width: 124px;
    }
  }
}

.productDetails {
  border: 1px solid $colour-oyster-grey;
  display: block;
  padding: 10px;
  position: relative;

  &::before {
    clear: both;
    content: '';
    display: table;
  }

  img {
    left: 50%;
    max-width: 290px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &.withBigMax {
      max-width: 400px;
    }
  }
}

.detailsContainer {
  height: 0;
  padding-top: 100%;
  position: relative;
}

.viewLargerImage {
  background-color: $colour-mushroom-grey;
  bottom: 0;
  border: 0;
  cursor: pointer;
  height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 40px;

  &::before {
    bottom: -1px;
    content: '\e90e';

    font: {
      family: 'WaitroseGlyph';
      size: 2em;
    }

    line-height: 40px;
    position: absolute;
    right: -1px;
    width: 40px;
  }
}

.modalOverlay {
  background-color: $colour-transparent;
  bottom: 0;
  left: 0;

  overflow: {
    x: hidden;
    y: auto;
  }

  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;

  &:global(.ReactModal__Overlay--before-close) {
    opacity: 0;
  }
}

.modalContent {
  background: $colour-white;
  border: 1px solid $colour-oyster-grey;
  bottom: auto;
  box-shadow: 0 0 5px 0 $colour-primary-grey--alpha-40;
  left: 50%;
  margin-right: -50%;
  max-width: 90%;
  outline: none;
  overflow: auto;
  padding: 40px;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);

  img {
    margin-bottom: 20px;
    max-width: 100%;
  }
}

.modalClose {
  color: $colour-chia-grey;

  &,
  &::after {
    cursor: pointer;
    height: 65px;
    line-height: 65px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transition: color .2s;
    width: 65px;
  }

  &::after {
    content: '\e912';

    font: {
      family: 'WaitroseGlyph';
      size: 40px;
    }
  }
}

.name {
  display: block;
  font-size: 14px;
  text-align: center;
}