@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../wdx/mixins/typography';

.productAttributes {
  list-style: none;
  margin: 0;
  padding: 0;
}

.grid {
  margin-left: -8px;
  @include clearfix;

  .attribute {
    margin-bottom: 10px;
  }
}

.icon {
  padding-right: 8px;
  line-height: normal;
}

.boxed {
  background-color: $colour-mushroom-grey;
  padding: 0 20px;
  border-radius: 16px;
  margin-bottom: 10px;
  line-height: 2;
  text-align: center;

  @include media-breakpoint-up('sm') {
    margin-right: 16px;
    display: inline-block;
  }

  > :first-child {
    margin-right: 7px;
  }
}

.bold {
  @include font-weight('bold');
}
